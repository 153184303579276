<template>
  <ModuleSubPage fullWidth>
    <BaseHasPermission :permissionNames="['role.update']">
      <v-row>
        <v-col cols="12">
          <BaseBackButton :route="{ name: 'ManagementRole' }">
            {{ $t("USER_MANAGEMENT.ROLES.BTN_LABEL_BACK") }}
          </BaseBackButton>
        </v-col>
        <v-col cols="12">
          <BaseHeadline size="3">
            {{ pageTitle }}
          </BaseHeadline>
        </v-col>
      </v-row>
      <AssignTable
        v-model="users"
        :fields="['email', 'email']"
        :loading="isLoading"
        @sync="syncRoleUsers($event)"
      />
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import AssignTable from "@components/AssignTable";

export default {
  name: "AssignUser",

  data() {
    return {
      isLoading: false,
      users: {
        assignable: [],
        assigned: [],
      },
      roleName: null,
    };
  },

  components: {
    AssignTable,
    ModuleSubPage,
  },

  computed: {
    roleId() {
      return parseInt(this.$route.params.roleId, 10);
    },

    pageTitle() {
      return this.$t("USER_MANAGEMENT.ROLES.ASSIGN_USERS_PAGE_TITLE", {
        roleName: this.isLoading ? "..." : this.roleName,
      });
    },
  },

  methods: {
    async loadRoleData() {
      try {
        const { data } = await this.$axios.userManagement.showRole({ roleId: this.roleId });
        this.roleName = data.data.displayName;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadAssignedUser() {
      try {
        const { data } = await this.$axios.userManagement.listUsers({
          inRoleIds: [this.roleId],
          perPage: 1000,
          page: 1,
        });
        this.users.assigned = data.data;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadAssignableUser() {
      try {
        const { data } = await this.$axios.userManagement.listUsers({
          perPage: 1000,
          page: 1,
        });

        this.users.assignable = data.data;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadUsers() {
      this.isLoading = true;

      try {
        await this.loadAssignedUser();
        await this.loadAssignableUser();

        return Promise.resolve();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async syncRoleUsers(userIds) {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.syncRoleUsers({ roleId: this.roleId, userIds });
        await this.loadUsers();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.loadRoleData();
    this.loadUsers();
  },
};
</script>
